// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_content_delivery_bucket": "app1-20190622222825-hostingbucket-master",
    "aws_content_delivery_bucket_region": "eu-central-1",
    "aws_content_delivery_url": "http://app1-20190622222825-hostingbucket-master.s3-website.eu-central-1.amazonaws.com",
    "aws_cognito_identity_pool_id": "eu-central-1:9f727264-19da-4494-8141-fe93a4397d5a",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_Q0QcCzqSU",
    "aws_user_pools_web_client_id": "1rpvbul7nb57dotgsupq2nmmsk",
    "oauth": {}
};


export default awsmobile;
