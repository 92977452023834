import Amplify from 'aws-amplify';
import { withAuthenticator } from 'aws-amplify-react';
import React from 'react';
import './App.css';
import awsconfig from './aws-exports';
import BarChart from './component/BarChart';
import Countries from './component/Countries';

Amplify.configure(awsconfig);

function App() {
  return (
    <div className="App">
      <BarChart></BarChart>
      <Countries></Countries>
    </div>
  );
}

export default withAuthenticator(App, true);
